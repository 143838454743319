<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/index' }">PC网站</el-breadcrumb-item>
      <el-breadcrumb-item>反馈留言</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="title" label="留言标题"></el-table-column>
        <el-table-column prop="content" label="留言内容"></el-table-column>
        <el-table-column prop="status" label="处理状态">
          <template slot-scope="scope">
            <el-tag type="info" effect="plain" v-if="scope.row.status === 1">未处理</el-tag>
            <el-tag type="success" effect="plain" v-if="scope.row.status === 2">已处理</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column fixed="right" label="操作" width="330">
          <template slot-scope="scope">
            <el-button size="small" type="success" @click="dealItem(scope.row.id)">设置为已处理</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      dataList: []
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      const { data: res } = await this.$http.get('/pc-message-list')
      if (res.status === 200) {
        this.dataList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async dealItem (id) {
      const { data: res } = await this.$http.put('/pc-message/', { id: id })
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getData()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.menu-area{
  padding: 0 0 20px 0;
  text-align: right;
}
.message-img{
  max-width: 100px;
  max-height: 50px;
}
</style>
